<template>
    <div id="file-drag-drop" class="card hover-img" @click="handleFileImport" >
      <form
        ref="fileform"
        class="grey darken-4"
        :class="dragActive"
        style="border-radius: 10px;border: 1px solid #EEEEEE;"
        >
        <img
          src="@/assets/png.png"
          alt=""
          style="width: 48px; margin-top: 70px"
        />
        <p class="mt-4">Drop your file or click to upload</p>
        <!-- <p class="fs-2 fw-thin">512px <span class="px-2">x</span> 512px</p> -->
        <div class="spinner-grow text-primary" role="status" v-if="uploading">
         
          <span class="visually-hidden green--text">Uploading...</span>
        </div>
      </form>
      <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
    </div>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config.js";
  export default {
    data() {
      return {
        image_url: "",
        dragAndDropCapable: false,
        files: [],
        uploadPercentage: 0,
        imageFile: null,
        uploading: false,
        dragOn: false,
      };
    },
    props: ["imageUrl"],
    computed: {
      dragActive() {
        if (this.dragOn) {
          return "drag-on";
        } else {
          return "";
        }
      },
    },
    mounted() {
      this.image_url = this.imageUrl;
      this.dragAndDropCapable = this.determineDragAndDropCapable();
      if (this.dragAndDropCapable) {
        [
          "drag",
          "dragstart",
          "dragend",
          "dragover",
          "dragenter",
          "dragleave",
          "drop",
        ].forEach(
          function (evt) {
            this.$refs.fileform.addEventListener(
              evt,
              function (e) {
                e.preventDefault();
                e.stopPropagation();
              }.bind(this),
              false
            );
          }.bind(this)
        );
        this.$refs.fileform.addEventListener(
          "drop",
          function (e) {
            this.dragOn = false;
            this.files = [];
            this.files.push(e.dataTransfer.files[0]);
            this.getImagePreviews();
            this.submitFiles();
          }.bind(this)
        );
        this.$refs.fileform.addEventListener(
          "dragenter",
          function (e) {
            this.dragOn = true;
            console.log("ENTER", e);
          }.bind(this)
        );
        this.$refs.fileform.addEventListener(
          "dragleave",
          function (e) {
            console.log("LEAVE", e);
            this.dragOn = false;
          }.bind(this)
        );
      }
    },
  
    methods: {
      determineDragAndDropCapable() {
        var div = document.createElement("div");
        return (
          ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
          "FormData" in window &&
          "FileReader" in window
        );
      },
  
      getImagePreviews() {
        for (let i = 0; i < this.files.length; i++) {
          if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
            let reader = new FileReader();
            reader.addEventListener(
              "load",
              function () {
                this.$refs["preview" + parseInt(i)][0].src = reader.result;
              }.bind(this),
              false
            );
            reader.readAsDataURL(this.files[this.files.length - 1]);
          }
        }
      },
      submitFiles() {
        this.uploading = true;
        let formData = new FormData();
        let context = this;
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          formData.append("files", file);
        }
        Axios.post(BASE_URL + "/upload-file", formData, {
          headers: {
            // 'Authorization': this.TOKEN.replace('%20', " "),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            console.log("progress" + progressEvent.loaded);
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this),
        })
          .then(function (response) {
            context.uploading = false;
            context.$emit("uploadSuccess", response.data);
            context.files = [];
            console.log("SUCCESS!!");
          })
          .catch(function () {
            context.uploading = false;
            context.$emit("uploadFailure", "FAILED");
            console.log("FAILURE!!");
          });
      },
      removeFile(key) {
        this.files.splice(key, 1);
      },
      handleFileImport() {
        window.addEventListener(
          "focus",
          () => {
            this.isSelecting = false;
          },
          { once: true }
        );
        this.$refs.uploader.click();
      },
      onFileChanged(e) {
        this.files = e.target.files;
        this.submitFiles();
      },
    },
  };
  </script>
  
  <style>
  form {
    display: block;
    height: 250px;
    width: 100%;
    background: #ffffff;
    margin: auto;
    text-align: center;
    /* line-height: 150px; */
  }
  
  div.file-listing {
    /* width: 400px; */
    margin: auto;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  div.file-listing img {
  }
  
  div.remove-container {
    text-align: center;
  }
  
  div.remove-container a {
    color: #009688;
    cursor: pointer;
  }
  
  .drag-on {
    border: 1px solid #009688 !important;
  }
  .upload-space:hover{
      border: 1px solid #009688 !important;
      border-radius:10px !important
  }
  .no-border {
    border: none;
  }
  a.submit-button {
    display: block;
    margin: auto;
    text-align: center;
    width: 200px;
    padding: 10px;
    text-transform: uppercase;
    background-color: #ccc;
    color: white;
    font-weight: bold;
    margin-top: 20px;
  }
  
  progress {
    width: 400px;
    margin: auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  </style>