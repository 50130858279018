<template>
  <div>
    <v-container>
      <v-layout row wrap>
        <h2 class="mt-5 grey--text text--darken-1">
          <router-link to="/projects" class="white--text">Projects</router-link>
          / {{ title }}
        </h2>
        <v-flex xs12 md12>
          <v-layout row wrap class="mt-5">
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Title</b></p>
              <v-text-field
                single-line
                outlined
                v-model="title"
                label="Title"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Tagline</b></p>
              <v-text-field
                single-line
                outlined
                v-model="tagline"
                label="Tagline"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Quote</b></p>
              <v-text-field
                single-line
                outlined
                v-model="quote"
                label="Quote"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Client</b></p>
              <v-text-field
                single-line
                outlined
                v-model="client"
                label="Client"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Location</b></p>
              <v-text-field
                single-line
                outlined
                v-model="location"
                label="Location"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Project Area</b></p>
              <v-text-field
                single-line
                outlined
                v-model="projectArea"
                label="Project Area"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Website</b></p>
              <v-text-field
                single-line
                outlined
                v-model="website"
                label="Website"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <p class="ma-0"><b>Description</b></p>
              <v-textarea
                single-line
                outlined
                v-model="description"
                label="Description"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2 md12>
          <h2 class="mt-5 mb-5">Gallery</h2>
          <uploader @uploadSuccess="uploadSuccess"></uploader>
          <div class="mt-5">
            <v-layout row wrap>
              <v-flex xs12 md6 v-for="item in images" :key="item" class="pa-2">
                <div
                  class="pa-2 pb-0 grey darken-4 picture"
                  style="position: relative"
                >
                  <div style="position: absolute; top: 10px; right: 10px">
                    <v-btn class="red" @click="removeImage(item)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      class="grey darken-2 ml-2"
                      
                      @click="setFeatured(item)"
                      ><v-icon v-if="item == featured_image" class="red--text">mdi-star</v-icon><v-icon small v-else>mdi-star</v-icon></v-btn
                    >
                  </div>

                  <img
                    :src="item"
                    style="max-width: 100%"
                    @click="viewImage(item)"
                  />
                </div>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex xs12 md12 class="pa-2">
          <v-btn @click="saveProject" class="primary" x-large
            >Save Details</v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 class="mt-5">
          <v-divider class="mt-5 mb-5"></v-divider>
          <h3 class="mb-5">Instructions</h3>
          <p>1. Drag and drop files to the upload box to upload images.</p>
          <p>2. For optimum perfomance the file sizes must be optimised preferably under 200KB each.</p>
          <p>3. WEBP format can prefered for the images for optimum perfomance</p>
          <p>4. Ideally the images should be sized 1920 x 1080 for better asthetics.</p>
          <p>5. Using images in portrait mode are discouraged</p>
          <p>6. Every project jas a featured image which is highlghted on the profile page. Featured image can be selected by pressing the star <v-icon>mdi-star</v-icon> button.</p>
        </v-flex>
      </v-layout>
      <v-dialog v-model="dialog" width="900">
        <v-card class="pa-2 pb-0">
          <img :src="selectedImage" style="width: 100%" />
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
  
  <script>
import Axios from "axios";
import { BASE_URL } from "../config";
import Uploader from "../components/Uploader.vue";
export default {
  data() {
    return {
      project: {},
      title: "",
      tagline: "",
      quote: "",
      client: "",
      location: "",
      projectArea: "",
      description: "",
      website: "",
      images: [],
      selectedImage: "",
      dialog: false,
      featured_image: "",
    };
  },
  components: {
    Uploader,
  },
  mounted() {
    this.fetchProject();
  },

  methods: {
    async fetchProject() {
      let url = BASE_URL + "/project/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.title = data.title;
      this.tagline = data.tagline;
      this.quote = data.quote;
      this.client = data.client;
      this.location = data.location;
      this.projectArea = data.projectArea;
      this.description = data.description;
      this.website = data.website;
      this.images = data.images;
      this.featured_image = data.featured_image;
    },
    uploadSuccess(value) {
      if (this.featured_image == "") {
        this.featured_image = value;
      }
      this.images.push(value);
    },
    removeImage(image) {
      this.images = this.images.filter((item) => item !== image);
    },
    async saveProject() {
      let url = BASE_URL + "/project/"+this.$route.params.id;
      let payload = {
        title: this.title,
        tagline: this.tagline,
        quote: this.quote,
        client: this.client,
        location: this.location,
        projectArea: this.projectArea,
        description: this.description,
        website: this.website,
        images: this.images,
        featured_image: this.featured_image,
      };
      let { data } = await Axios.put(url, payload);
      alert(JSON.stringify(data))
    },
    viewImage(image) {
      this.selectedImage = image;
      this.dialog = true;
    },
    setFeatured(item) {
      this.featured_image = item;
    },
  },
};
</script>

<style>
.picture img {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>